import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Container, Box, Card, CardContent, Typography, Grid } from "@mui/material";
import emoteHijau from "../../../imgAVIF/emote_hijau.avif";
import emoteKuning from "../../../imgAVIF/emote_kuning.avif";
import emoteMerah from "../../../imgAVIF/emote_merah.avif";
import TULT from "../../../imgAVIF/TULT.avif";
import GKU from "../../../imgAVIF/GKU.avif";
import Deli from "../../../imgAVIF/Deli.avif"
import GPSMap from "../AQMSAll/GPS"

// Fungsi untuk mendapatkan nilai terbaru
const getLatestValue = (dataArray) => {
  if (dataArray.length === 0) return null; // jika array kosong
  const latest = dataArray[dataArray.length - 1]; // ambil elemen terakhir
  return latest; // kembalikan objek terbaru
};

const formatValue = (value) => {
  if (value === null || value === undefined || isNaN(value)) return "-";
  return value;
};

// Fungsi untuk memeriksa apakah data berada dalam 2 jam terakhir
const isWithinLastTwoHours = (timestamp) => {
  const now = Date.now();
  const twoHoursAgo = now - 2 * 60 * 60 * 1000; // 2 jam dalam milidetik
  return new Date(timestamp).getTime() > twoHoursAgo;
};

const getCO2Level = (co2) => {
  if (co2 < 800) return { emote: emoteHijau };
  if (co2 >= 800 && co2 <= 1000) return { emote: emoteKuning };
  return { emote: emoteMerah };
};

const getPM25Level = (pm25) => {
  if (pm25 < 10) return { emote: emoteHijau };
  if (pm25 >= 10 && pm25 <= 50) return { emote: emoteKuning };
  return { emote: emoteMerah };
};
const directionFromDegrees = (deg) => {
  console.log('Wind Direction (deg):', deg); // Log nilai derajat
  if (deg === null || deg === undefined)
    return null;

  let direction;
  if (0 <= deg && deg < 22.5 || 337.5 <= deg && deg <= 360) direction = "N";
  else if (22.5 <= deg && deg < 67.5) direction = "NE";
  else if (67.5 <= deg && deg < 112.5) direction = "E";
  else if (112.5 <= deg && deg < 157.5) direction = "SE";
  else if (157.5 <= deg && deg < 202.5) direction = "S";
  else if (202.5 <= deg && deg < 247.5) direction = "SW";
  else if (247.5 <= deg && deg < 292.5) direction = "W";
  else if (292.5 <= deg && deg < 337.5) direction = "NW";

  console.log('Converted Wind Direction:', direction); // Log hasil konversi
  return direction;
};


const StationCard = ({ stationName, pm25, co2, temperature, humidity, ws, wd, image, isTopCard, height }) => {
  const co2Level = co2 !== null ? getCO2Level(co2) : { emote: null };
  const pm25Level = pm25 !== null ? getPM25Level(pm25) : { emote: null };

  return (
    <Card
      sx={{
        width: 222.28,
        height: isTopCard ? 400 : 364, // Ukuran lebih panjang untuk card gedung
        backgroundColor: image ? "transparent" : "#E3F2FD",
        margin: 0,
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.6)',
        position: "relative",
        overflow: "hidden"
      }}
    >
      {image && (
        <Box
          component="img"
          sx={{
            height: '88%',
            width: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0
          }}
          alt={stationName}
          src={image}
        />
      )}

      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: 32,
            position: isTopCard ? 'absolute' : 'initial',
            top: isTopCard ? 8 : 'initial',
            left: isTopCard ? 8 : 'initial',
            ...(isTopCard && {
              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Latar belakang putih semi-transparan
              color: 'black', // Warna teks hitam
              padding: '4px 8px',
              borderRadius: '4px',
            }),
          }}
        >
          {stationName}
        </Typography>

        {!isTopCard && (
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            {/* PM2.5 Section */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    PM2.5
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                      {formatValue(pm25)}
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', color: "#000", fontSize: 12 }}>
                      µg/m³
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {pm25Level.emote && <img src={pm25Level.emote} alt="PM2.5 Emote" style={{ width: 40, height: 40 }} />}
              </Grid>
            </Grid>

            {/* CO2 Section */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    CO2
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                      {formatValue(co2)}
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', color: "#000", fontSize: 12 }}>
                      ppm
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {co2Level.emote && <img src={co2Level.emote} alt="CO2 Emote" style={{ width: 40, height: 40 }} />}
              </Grid>
            </Grid>

            {/* Temperature and Humidity Sections */}
            <Grid container spacing={2} justifyContent="center" mt={2}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    Temperature
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                      {formatValue(temperature)}
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', color: "#000", fontSize: 12 }}>
                      °C
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    Humidity
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                      {formatValue(humidity)}
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', color: "#000", fontSize: 12 }}>
                      %
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Wind Speed and Direction Sections */}
            <Grid container spacing={2} justifyContent="center" mt={2}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    Wind Speed
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                      {formatValue(ws)}
                    </Typography>
                    <Typography variant="body2" sx={{ ml: 0.5, fontWeight: 'bold', color: "#000", fontSize: 12 }}>
                      m/s
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box>
                  <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#555" }}>
                    Wind Direction
                  </Typography>
                  <Typography variant="h4" sx={{ fontFamily: 'Poppins', fontWeight: 'bold', color: "#000", fontSize: 20 }}>
                    {directionFromDegrees(formatValue(wd))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
      {isTopCard && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            fontSize: 25,
            position: 'absolute',
            bottom: 8, // Tetap di bagian bawah
            left: '50%', // Posisikan di tengah secara horizontal
            transform: 'translateX(-50%)', // Untuk memastikan posisi tepat di tengah
            textAlign: 'center', // Pastikan teks berada di tengah
            color: '#163461', // Warna sesuai permintaan
            zIndex: 2 // Pastikan di atas semua elemen lain

          }}
        >
          {height} m
        </Typography>
      )}
    </Card>
  );
};

const stations = ['TULT', 'GKU', 'Deli']; // Simpan nama stasiun di luar agar bisa digunakan berulang kali

const AllStationAQMS = () => {
  const [data, setData] = useState(
    Object.fromEntries(stations.map(station => [station, {
      temperature: null, humidity: null, co2: null, pm25: null, ws: null, wd: null
    }]))
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: result } = await axios.get("https://biru-langit.com/api/telkom/all");
        console.log(result); // Log API result
        const updatedData = Object.fromEntries(
          stations.map(station => [station, result[station]])
        );
        console.log(updatedData); // Log updatedData to check if it is correctly mapped
        setData(updatedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 120000); // Setiap 2 menit
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <section className="bg"> {/* Background section */}
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 1920,
            height: 1196,
            position: "relative",
            margin: "0 auto",
            overflow: "hidden",
          }}
        >

          <Grid container spacing={4}>
            {/* Grid untuk GPSMap dan Legend */}
            <Grid item xs={4}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {/* GPSMap ditempatkan di atas legend */}
                <GPSMap />
                <Box sx={{ marginTop: 2 }}> {/* Tambahkan margin untuk memisahkan GPSMap dan Legend */}
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    {/* Legenda CO2 */}
                    <Box sx={{ width: "48%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          height: 65,
                          backgroundColor: "#E3F2FD",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid black",
                          marginBottom: 3,
                        }}
                      >
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: "semi-bold", fontSize: 24 }}>
                          CO2
                        </Typography>
                      </Box>
                      {[
                        { label: "< 800 Low", color: "#163461", emote: emoteHijau },
                        { label: "800 - 1000 Medium", color: "#163461", emote: emoteKuning },
                        { label: "> 1000 High", color: "#163461", emote: emoteMerah },
                      ].map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            height: 75,
                            backgroundColor: item.color,
                            borderRadius: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px",
                            marginBottom: 3,
                          }}
                        >
                          <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: 19, color: "#fff" }}>
                            {item.label}
                          </Typography>
                          <img src={item.emote} alt={`emote-${item.label.toLowerCase()}`} style={{ width: 45, height: 45 }} />
                        </Box>
                      ))}
                    </Box>

                    {/* Legenda PM 2.5 */}
                    <Box sx={{ width: "48%" }}>
                      <Box
                        sx={{
                          width: "100%",
                          height: 65,
                          backgroundColor: "#E3F2FD",
                          borderRadius: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid black",
                          marginBottom: 3,
                        }}
                      >
                        <Typography sx={{ fontFamily: "Poppins", fontWeight: "semi-bold", fontSize: 24 }}>
                          PM 2.5
                        </Typography>
                      </Box>
                      {[
                        { label: "< 10 Low", color: "#163461", emote: emoteHijau },
                        { label: "10 - 50 Medium", color: "#163461", emote: emoteKuning },
                        { label: "> 50 High", color: "#163461", emote: emoteMerah },
                      ].map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            height: 75,
                            backgroundColor: item.color,
                            borderRadius: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 20px",
                            marginBottom: 3,
                          }}
                        >
                          <Typography sx={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: 19, color: "#fff" }}>
                            {item.label}
                          </Typography>
                          <img src={item.emote} alt={`emote-${item.label.toLowerCase()}`} style={{ width: 45, height: 45 }} />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Posisi card data di sebelah kanan */}
            <Grid item xs={8}>
              <Box sx={{ width: 800, height: "auto", position: "relative" }}>
                <Grid container spacing={4}> {/* Grid untuk card gedung */}
                  {/* Row pertama untuk gedung */}
                  <Grid item>
                    <StationCard stationName="TULT" image={TULT} isTopCard height={70} /> {/* Ketinggian 70 meter */}
                  </Grid>
                  <Grid item>
                    <StationCard stationName="GKU" image={GKU} isTopCard height={45} /> {/* Ketinggian 45 meter */}
                  </Grid>
                  <Grid item>
                    <StationCard stationName="Deli" image={Deli} isTopCard height={15} /> {/* Ketinggian 15 meter */}
                  </Grid>

                </Grid>
                {/* Row kedua untuk data */}
                <Grid container spacing={4} sx={{ marginTop: 5 }}>
                  {stations.map((station, index) => (
                    <Grid item key={station}>
                      <StationCard
                        stationName={station}
                        pm25={data[station].pm25}
                        co2={data[station].co2}
                        temperature={data[station].temperature}
                        humidity={data[station].humidity}
                        ws={data[station].ws}
                        wd={data[station].wd}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default AllStationAQMS;